import React from "react"
import { StaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import PostsComponent from "../components/posts"

const IndexPage = () => (
  <Layout title="Home">
    <StaticQuery
      query={graphql`
        query {
          allStrapiPost(sort: {fields: [published] order: DESC}) {
            edges {
              node {
                strapiId
                slug
                title
                published
                card_image_description
                card_image {
                  publicURL
                }
                categories {
                  name
                  slug
                }
              }
            }
          }
        }
      `}
      render={data => (
        <div className="uk-section">
          <div className="uk-container uk-container-small">
            <h1 className="ui header">Rythnyx</h1>
            <hr/>
            <PostsComponent posts={data.allStrapiPost.edges} />
          </div>
        </div>
      )}
    />
  </Layout>
)

export default IndexPage